import React, { useState } from "react";
import { Button, SEO } from "~components";
import COLORS from "~data/colors.json";
import cn from "classnames";
import * as styles from "./styles.module.scss";

/** ============================================================================
 * @page
 * ASG Editor
 */
const ASG = () => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const [updatedColors, setUpdatedColors] = useState(COLORS);
  const [submitting, setSubmitting] = useState(false);

  // ---------------------------------------------------------------------------
  // variables

  const colorKeys = Object.keys(updatedColors);
  const readOnlyColorKeys = Array(10)
    .fill(null)
    .map((_, index) => {
      if (index === 0) {
        return `black`;
      }

      return `black-${index * 10}`;
    });
  readOnlyColorKeys.push(`white`);
  readOnlyColorKeys.push(`ux-error`);
  readOnlyColorKeys.push(`ux-success`);

  // ---------------------------------------------------------------------------
  // methods

  const addColor = (e) => {
    e.preventDefault();

    if (!e?.target?.[0]?.value) {
      return;
    }

    const label = e.target[0].value;
    const key = label.replaceAll(` `, `-`).toLowerCase();

    if (updatedColors?.[key]) {
      return;
    }

    e.target[0].value = ``;

    setUpdatedColors({
      ...updatedColors,
      [key.replaceAll(` `, `-`).toLowerCase()]: {
        id: key,
        swatch: label,
        mono: false,
        hex: `#000000`,
        display: `light`
      }
    });
  };

  const deleteColor = (key) => {
    if (!updatedColors?.[key]) {
      return;
    }

    const colorsClone = JSON.parse(JSON.stringify(updatedColors));

    delete colorsClone?.[key];

    setUpdatedColors(colorsClone);
  };

  const onChange = (e) => {
    const colorKey = e.currentTarget.name;

    const objectKey = e.currentTarget.getAttribute(`objectKey`);
    if (!objectKey) {
      return;
    }

    const colorData = updatedColors?.[colorKey];
    if (!colorData) {
      return;
    }

    setUpdatedColors({
      ...updatedColors,
      [colorKey]: {
        ...colorData,
        [objectKey]:
          e.currentTarget.type === `checkbox`
            ? e.currentTarget.checked
            : e.currentTarget.value
      }
    });
  };

  const writeJSON = () => {
    if (submitting) {
      return;
    }

    setSubmitting(true);

    fetch(`${process.env.GATSBY_SITE_URL}/api/write-colors`, {
      body: JSON.stringify(updatedColors),
      headers: new Headers({
        "Content-Type": `application/json`
      }),
      method: `POST`
    })
      .then((res) => res.json())
      // eslint-disable-next-line no-unused-vars
      .then((res) => {
        // console.log(`res: `, res);

        setSubmitting(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // ---------------------------------------------------------------------------
  // render

  return (
    <>
      <SEO pageTitle="ASG" />

      <div className={styles.container}>
        <div className={styles.saveWidget}>
          <Button onClick={writeJSON} disabled={submitting}>
            <span className="button-text">
              {submitting ? `SAVING` : `SAVE`}
            </span>
          </Button>
        </div>

        <>
          {colorKeys.map((colorKey) => {
            const color = updatedColors?.[colorKey];

            if (!color) {
              return null;
            }

            const objectKeys = Object.keys(color);
            const defaultColor = readOnlyColorKeys?.includes(colorKey);

            return (
              <div
                className={styles.inputGroup}
                key={`color-${colorKey}`}
                style={{ padding: defaultColor ? `0.5rem 0` : `3rem 0 2rem` }}
              >
                <header
                  className={styles.inputGroupHeader}
                  style={{
                    marginBottom: defaultColor ? `0` : `1rem`,
                    defaultColor: defaultColor
                      ? `var(--color-black-40)`
                      : `black`
                  }}
                  defaultColor={defaultColor}
                >
                  <div
                    className={styles.colorIcon}
                    style={{ background: color?.hex || `white` }}
                  />

                  <p className="h2">{colorKey}</p>

                  {(defaultColor && color?.hex && (
                    <p className={cn(`b1`, styles.hexText)}>
                      {color.hex} (default)
                    </p>
                  )) || (
                    <Button
                      buttonType="submit"
                      className={styles.deleteButton}
                      onClick={() => deleteColor(colorKey)}
                    >
                      <span className="button-text">DELETE</span>
                    </Button>
                  )}
                </header>

                {!defaultColor &&
                  objectKeys.map((objectKey) => {
                    if (!objectKey || objectKey === `id`) {
                      return null;
                    }

                    const value = color[objectKey];

                    let inputJSX;

                    switch (objectKey) {
                      case `mono`:
                        inputJSX = (
                          <input
                            className="b1"
                            name={colorKey}
                            objectKey={objectKey}
                            defaultValue={value}
                            onChange={onChange}
                            type="checkbox"
                            defaultChecked={value === true}
                          />
                        );

                        break;

                      case `display`:
                        inputJSX = (
                          <select
                            className="b1"
                            name={colorKey}
                            objectKey={objectKey}
                            onChange={onChange}
                          >
                            {[`Dark`, `Light`].map((theme) => {
                              // eslint-disable-next-line
                              let i;

                              return (
                                <option
                                  key={`select-${theme}`}
                                  selected={theme?.toLowerCase() === value}
                                >
                                  {theme}
                                </option>
                              );
                            })}
                          </select>
                        );

                        break;

                      default:
                        inputJSX = (
                          <input
                            className="b1"
                            name={colorKey}
                            objectKey={objectKey}
                            defaultValue={value}
                            onChange={onChange}
                            type="text"
                          />
                        );
                        break;
                    }

                    return (
                      <div
                        className={styles.objectEditor}
                        key={`object-${objectKey}`}
                      >
                        <p className={cn(`caption`, styles.inputLabel)}>
                          {objectKey}:
                        </p>

                        {inputJSX}
                      </div>
                    );
                  })}
              </div>
            );
          })}

          <div>
            <form onSubmit={addColor}>
              <input className="b1" name="key" type="text" />

              <Button buttonType="submit" className={styles.addButton}>
                <span className="button-text">ADD</span>
              </Button>
            </form>
          </div>
        </>
      </div>
    </>
  );
};

export default ASG;
