// extracted by mini-css-extract-plugin
export var addButton = "styles-module--addButton--q2EYr";
export var colorIcon = "styles-module--colorIcon--sv3JI";
export var container = "styles-module--container--aUmZg";
export var deleteButton = "styles-module--deleteButton--w8EJU";
export var hexText = "styles-module--hexText--K9s7Q";
export var inputGroup = "styles-module--inputGroup--0xDxj";
export var inputGroupHeader = "styles-module--inputGroupHeader--TvQL-";
export var inputLabel = "styles-module--inputLabel--yUBXJ";
export var objectEditor = "styles-module--objectEditor--aJYFL";
export var saveWidget = "styles-module--saveWidget--YdsQc";